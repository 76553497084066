import { fetchUtils, withLifecycleCallbacks } from 'react-admin'

import { stringify } from 'query-string'
import { apiUrl } from './config/constants'

const omitBy = require('lodash.omitby')
const isNull = require('lodash.isnull')
const upperFirst = require('lodash.upperfirst')

const httpClient = fetchUtils.fetchJson

const buildAuthHeaders = () => {
  const token = localStorage.getItem('auth')
  const headers = new Headers({ Accept: 'application/json', auth: token })

  return headers
}

export const baseDataProvider = {
  getList: (resource, params) => {
    const endpointId = `index${upperFirst(resource)}`
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    let sortOrder = order || 'DESC'
    let sortBy = field || 'createdAt'
    if (sortBy === 'id') {
      sortBy = 'createdAt'
      sortOrder = 'DESC'
    }

    const skip = (page -1) * perPage
    const query = {
      sortOrder,
      sortBy,
      skip,
      limit: perPage,
    }

    const url = `${apiUrl}/${endpointId}?${stringify(query)}`

    const headers = buildAuthHeaders()

    return httpClient(url, { headers }).then(({ headers, json }) => ({
      data: json,
      total: Number(headers.get('x-total-count')) || json.length
    }))
  },

  getOne: (resource, params) => {
    const modelName = resource.slice(0, -1)
    const endpointId = `read${upperFirst(modelName)}`
    const url = `${apiUrl}/${endpointId}?id=${params.id}`

    const headers = buildAuthHeaders()

    return httpClient(url, { headers }).then(({ json }) => ({
      data: json,
    }))
  },

  getMany: (resource, params) => {
    const endpointId = `index${upperFirst(resource)}`
    const { ids } = params
    // const query = {
    //   filter: JSON.stringify({ id: params.ids }),
    // }
    // const url = `${apiUrl}/${endpointId}?${stringify(query)}`
    const headers = buildAuthHeaders()
    const url = `${apiUrl}/${endpointId}?ids=${ids}`
    return httpClient(url, { headers }).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const endpointId = `index${upperFirst(resource)}`
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${endpointId}?${stringify(query)}`

    const headers = buildAuthHeaders()

    return httpClient(url, { headers }).then(({ headers, json }) => ({
      data: json,
      total: parseInt(
        headers
          .get('content-range')
          .split('/')
          .pop(),
        10
      ),
    }))
  },

  update: (resource, params) => {
    const modelName = resource.slice(0, -1)
    const endpointId = `update${upperFirst(modelName)}`

    const headers = buildAuthHeaders()

    const bodyParams = omitBy(params.data, isNull)

    return httpClient(`${apiUrl}/${endpointId}?id=${params.id}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) => {
    const headers = buildAuthHeaders()
    const modelName = resource.slice(0, -1)
    let endpointId = `create${upperFirst(modelName)}`

    if (modelName === 'film') {
      endpointId = 'createFilmFromTmdb'
    }

    return httpClient(`${apiUrl}/${endpointId}`, {
      headers,
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  },

  delete: (resource, params) => {
    const headers = buildAuthHeaders()
    const modelName = resource.slice(0, -1)
    const endpointId = `delete${upperFirst(modelName)}`
    return httpClient(`${apiUrl}/${endpointId}?id=${params.id}`, {
      headers,
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },
}

export const dataProvider = withLifecycleCallbacks(baseDataProvider, [
  {
    resource: 'films',
    beforeCreate: async (params, dataProvider) => {
      if (params.data.image) {
        const formData = new FormData()
        formData.append('file', params.data.image.rawFile)

        const headers = buildAuthHeaders()
        const middlewareId = 'media/films'
        const { json } = await httpClient(`${apiUrl}/${middlewareId}`, {
          headers,
          method: 'POST',
          body: formData,
        })

        const { url } = json

        params.data.imageUrl = url
        delete params.data.image
      }

      return params
    }
  },
  {
    resource: 'shows',
    beforeCreate: async (params, dataProvider) => {
      if (params.data.image) {
        const formData = new FormData()
        formData.append('file', params.data.image.rawFile)

        const headers = buildAuthHeaders()
        const middlewareId = 'media/shows'
        const { json } = await httpClient(`${apiUrl}/${middlewareId}`, {
          headers,
          method: 'POST',
          body: formData,
        })

        const { url } = json

        params.data.imageUrl = url
        delete params.data.image
      }

      return params
    }
  }
])