import {
  List,
  Datagrid,
  TextField,
  // RichTextField,
  ImageField,
  UrlField,
  NumberField,
  BooleanField,
  ChipField,
  // ReferenceArrayField,
  // SingleFieldList,
  DeleteWithConfirmButton,
  Edit,
  SimpleForm,
  Create,
  required,
  TextInput,
  NumberInput,
  SelectInput,
  ImageInput,
  BooleanInput,
  // ArrayInput,
  // SimpleFormIterator,
  CheckboxGroupInput,
  DateInput,
  ReferenceInput,
} from 'react-admin'

// <List filters={postFilters}>
// const postFilters = [
//     <TextInput source='q' label='Search' alwaysOn />,
//     <ReferenceInput source='userId' label='User' reference='users' />,
// ]

// <Edit title={<PostTitle />}>
// const PostTitle = () => {
//   const record = useRecordContext()
//   return <span>Post {record ? `'${record.title}'` : ''}</span>
// }

export const FilmList = () => (
  <List>
    <Datagrid rowClick='edit'>
      <TextField source="title" />
      <ImageField source="imageUrl" title="imageUrl" />
      <UrlField source="linkUrl" />
      <NumberField source="wordsCount" />
      <BooleanField source="isSeries" />
      <NumberField source="duration" />
      <NumberField source="season" />
      <NumberField source="episode" />
      <ChipField source="original" />
      <ChipField source="classification" />
      <DeleteWithConfirmButton confirmContent="This action could not be reverted. Are you sure?" />
    </Datagrid>
  </List>
)

export const FilmEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" />
      <TextInput source="tagline" fullWidth resettable />
      <NumberInput source="duration" label="Duration in minutes" />
      <ImageField source="imageUrl" title="imageUrl" />
      <TextInput source="linkUrl" type="url" fullWidth resettable />
      <SelectInput source="original" choices={[
        { id: 'eng', name: 'English' },
      ]} />
      <SelectInput source="classification" choices={[
        { id: 'G', name: 'G' },
        { id: 'PG', name: 'PG' },
        { id: 'PG-13', name: 'PG-13' },
        { id: 'R', name: 'R' },
        { id: 'NC-17', name: 'NC-17' },
      ]} />
      <CheckboxGroupInput source="genres" choices={[
        { id: 'Action', name: 'Action' },
        { id: 'Adventure', name: 'Adventure' },
        { id: 'Animation', name: 'Animation' },
        { id: 'Comedy', name: 'Comedy' },
        { id: 'Crime', name: 'Crime' },
        { id: 'Documentary', name: 'Documentary' },
        { id: 'Drama', name: 'Drama' },
        { id: 'Family', name: 'Family' },
        { id: 'Fantasy', name: 'Fantasy' },
        { id: 'History', name: 'History' },
        { id: 'Horror', name: 'Horror' },
        { id: 'Music', name: 'Music' },
        { id: 'Mystery', name: 'Mystery' },
        { id: 'Romance', name: 'Romance' },
        { id: 'Science Fiction', name: 'Science Fiction' },
        { id: 'Thriller', name: 'Thriller' },
        { id: 'TV Movie', name: 'TV Movie' },
        { id: 'War', name: 'War' },
        { id: 'Western', name: 'Western' },
      ]} />
      <DateInput source="releaseDate" />
      <BooleanInput source="isSeries" />
      <NumberInput source="season" />
      <NumberInput source="episode" />
      <ReferenceInput source="showId" reference="shows" />
      <TextInput source="tmdbId" label="TMDB ID" />
      <TextInput source="imdbId" label="IMDB ID" />
      <NumberInput source="rating" />
    </SimpleForm>
  </Edit>
)

export const FilmCreateWithTMDB = () => (
  <Create>
    <SimpleForm>
      <TextInput source="tmdbId" label="TMDB ID" validate={[required()]} />
    </SimpleForm>
  </Create>
)

export const FilmCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" validate={[required()]} />
      <TextInput source="description"  multiline fullWidth resettable />
      <TextInput source="tagline" fullWidth resettable />
      <NumberInput source="duration" label="Duration in minutes" />
      <ImageInput source="image" label="Film Image">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="linkUrl" type="url" validate={[required()]} fullWidth resettable />
      <SelectInput source="original" validate={[required()]} choices={[
        { id: 'eng', name: 'English' },
      ]} />
      <SelectInput source="classification" validate={[required()]} choices={[
        { id: 'G', name: 'G' },
        { id: 'PG', name: 'PG' },
        { id: 'PG-13', name: 'PG-13' },
        { id: 'R', name: 'R' },
        { id: 'NC-17', name: 'NC-17' },
      ]} />
      <CheckboxGroupInput source="genres" validate={[required()]} choices={[
        { id: 'Action', name: 'Action' },
        { id: 'Adventure', name: 'Adventure' },
        { id: 'Animation', name: 'Animation' },
        { id: 'Comedy', name: 'Comedy' },
        { id: 'Crime', name: 'Crime' },
        { id: 'Documentary', name: 'Documentary' },
        { id: 'Drama', name: 'Drama' },
        { id: 'Family', name: 'Family' },
        { id: 'Fantasy', name: 'Fantasy' },
        { id: 'History', name: 'History' },
        { id: 'Horror', name: 'Horror' },
        { id: 'Music', name: 'Music' },
        { id: 'Mystery', name: 'Mystery' },
        { id: 'Romance', name: 'Romance' },
        { id: 'Science Fiction', name: 'Science Fiction' },
        { id: 'Thriller', name: 'Thriller' },
        { id: 'TV Movie', name: 'TV Movie' },
        { id: 'War', name: 'War' },
        { id: 'Western', name: 'Western' },
      ]} />
      <DateInput source="releaseDate" validate={[required()]} />
      <BooleanInput source="isSeries" />
      <NumberInput source="season" />
      <NumberInput source="episode" />
      <ReferenceInput source="showId" reference="shows" />
      <TextInput source="tmdbId" label="TMDB ID" validate={[required()]} />
      <NumberInput source="rating" />
      <TextInput source="text" validate={[required()]} multiline fullWidth resettable/>
    </SimpleForm>
  </Create>
)
