import { fetchUtils } from 'react-admin'
import { apiUrl, loginOperationId } from './config/constants'

const httpClient = fetchUtils.fetchJson

export const authProvider = {
  // called when the user attempts to log in
  login: async (values) => {
    let token
    if ((values.type && values.type === 'wallet') || values.type === 'email') {
      const { signature, digest, email, password } = values

      let payload = { email, password }
      if (values.type === 'wallet') {
        payload = { signature, digest }
      }

      const operationId = loginOperationId(values.type)
      const response = await httpClient(`${apiUrl}/${operationId}`, {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      token = response.json.token
    } else {
      // createSessionByEmail (email, password)
    }
    // { email, password }
    localStorage.setItem('auth', token)
    // accept all username/password combinations
    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
}
