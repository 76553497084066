export const loginMessage = 'Log in to Movie Mingle'
export const apiUrl = process.env.REACT_APP_BACKEND_API
export const loginType = 'email'

export const loginOperationId = (type) => {
  let operationId = 'createSessionByEmail'

  if (type === 'wallet') {
    operationId = 'createSessionByWallet'
  }

  return operationId
}
