import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  ImageField,
  UrlField,
  // NumberField,
  // BooleanField,
  ChipField,
  // ReferenceArrayField,
  // SingleFieldList,
  DeleteWithConfirmButton,
  Edit,
  SimpleForm,
  Create,
  required,
  TextInput,
  NumberInput,
  SelectInput,
  ImageInput,
  // BooleanInput,
  // ArrayInput,
  // SimpleFormIterator,
  CheckboxGroupInput,
  DateInput,
  // ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'

// <List filters={postFilters}>
// const postFilters = [
//     <TextInput source='q' label='Search' alwaysOn />,
//     <ReferenceInput source='userId' label='User' reference='users' />,
// ]

// <Edit title={<PostTitle />}>
// const PostTitle = () => {
//   const record = useRecordContext()
//   return <span>Post {record ? `'${record.title}'` : ''}</span>
// }

export const ShowList = () => (
  <List>
    <Datagrid rowClick='edit'>
      <TextField source="title" />
      <RichTextField source="description" />
      <ImageField source="imageUrl" title="imageUrl" />
      <UrlField source="linkUrl" />
      <ChipField source="original" />
      <DeleteWithConfirmButton confirmContent="This action could not be reverted. Are you sure?" />
    </Datagrid>
  </List>
)

export const ShowEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" />
      <ImageField source="imageUrl" title="imageUrl" />
      <TextInput source="linkUrl" type="url" fullWidth resettable />
      <SelectInput source="original" choices={[
        { id: 'eng', name: 'English' },
      ]} />
      <CheckboxGroupInput source="genres" choices={[
        { id: 'Action', name: 'Action' },
        { id: 'Adventure', name: 'Adventure' },
        { id: 'Animation', name: 'Animation' },
        { id: 'Comedy', name: 'Comedy' },
        { id: 'Crime', name: 'Crime' },
        { id: 'Documentary', name: 'Documentary' },
        { id: 'Drama', name: 'Drama' },
        { id: 'Family', name: 'Family' },
        { id: 'Fantasy', name: 'Fantasy' },
        { id: 'History', name: 'History' },
        { id: 'Horror', name: 'Horror' },
        { id: 'Music', name: 'Music' },
        { id: 'Mystery', name: 'Mystery' },
        { id: 'Romance', name: 'Romance' },
        { id: 'Science Fiction', name: 'Science Fiction' },
        { id: 'Thriller', name: 'Thriller' },
        { id: 'TV Movie', name: 'TV Movie' },
        { id: 'War', name: 'War' },
        { id: 'Western', name: 'Western' },
      ]} />
      <DateInput source="releaseDate" />
      <ArrayInput source="seasons">
        <SimpleFormIterator inline>
          <TextInput source="description" />
          <NumberInput source="number" min="1" helperText={false} />
          <NumberInput source="seriesNumber" min="1" helperText={false} />
          <ImageField source="imageUrl" title="imageUrl" />
          <TextInput source="linkUrl" type="url" fullWidth resettable />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="text" />
    </SimpleForm>
  </Edit>
)

export const ShowCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" validate={[required()]} />
      <TextInput source="description"  multiline fullWidth resettable />
      <ImageInput source="image" label="Show Image">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="linkUrl" type="url" fullWidth resettable />
      <SelectInput source="original" choices={[
        { id: 'eng', name: 'English' },
      ]} />
      <CheckboxGroupInput source="genres" choices={[
        { id: 'Action', name: 'Action' },
        { id: 'Adventure', name: 'Adventure' },
        { id: 'Animation', name: 'Animation' },
        { id: 'Comedy', name: 'Comedy' },
        { id: 'Crime', name: 'Crime' },
        { id: 'Documentary', name: 'Documentary' },
        { id: 'Drama', name: 'Drama' },
        { id: 'Family', name: 'Family' },
        { id: 'Fantasy', name: 'Fantasy' },
        { id: 'History', name: 'History' },
        { id: 'Horror', name: 'Horror' },
        { id: 'Music', name: 'Music' },
        { id: 'Mystery', name: 'Mystery' },
        { id: 'Romance', name: 'Romance' },
        { id: 'Science Fiction', name: 'Science Fiction' },
        { id: 'Thriller', name: 'Thriller' },
        { id: 'TV Movie', name: 'TV Movie' },
        { id: 'War', name: 'War' },
        { id: 'Western', name: 'Western' },
      ]} />
      <DateInput source="releaseDate" />
      <ArrayInput source="seasons">
        <SimpleFormIterator inline>
          <TextInput source="description" />
          <NumberInput source="number" min="1" helperText={false} />
          <NumberInput source="seriesNumber" min="1" helperText={false} />
          <ImageInput source="seasonImage" label="Season Image">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="linkUrl" type="url" fullWidth resettable />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="text" validate={[required()]} multiline fullWidth resettable/>
    </SimpleForm>
  </Create>
)
