import {
  List,
  Datagrid,
  ChipField,
  // NumberField,
  // DateField,
  EmailField,
  TextField,
  BooleanField,
  ImageField,
} from 'react-admin'

export const UserProfileList = () => (
  <List>
    <Datagrid>
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <ImageField source="avatarUrl" title="avatarUrl" />
      <ChipField source="knownLanguage" />
      <ChipField source="currentLearningLanguage" />
      <BooleanField source="isLanguagesSet" />
      <BooleanField source="isInitialVocabularySet" />
    </Datagrid>
  </List>
)
