import * as React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Button, CardContent, CircularProgress } from '@mui/material'
import { Form, required, useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core'
import { TextInput } from 'react-admin'

export const LoginForm = (props) => {
  const { redirectTo, className } = props
  const [loading, setLoading] = useSafeSetState(false)
  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()

  const submit = (values) => {
    setLoading(true)
    values.type = 'email'
    login(values, redirectTo)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'error',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        )
      })
  }

  return (
    <StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
      <CardContent className={LoginFormClasses.content}>
        <TextInput autoFocus source="email" label="Email" validate={required()} fullWidth />
        <TextInput
          source="password"
          label={translate('ra.auth.password')}
          type="password"
          autoComplete="current-password"
          validate={required()}
          fullWidth
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.sign_in')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  )
}

const PREFIX = 'RaLoginForm'

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}))

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}
