import * as React from 'react'

import { Card, Avatar, Button } from '@mui/material'
import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import { useNavigate } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import { useLogin, useNotify } from 'react-admin'
import { connectMetamask } from './services/metamask/index'
import { signMessage } from './services/metamask/provider'

import { LoginForm as DefaultLoginForm } from './LoginForm'

import { loginMessage, loginType } from './config/constants'

const isEmailLogin = loginType === 'email' ? true : false

export const Login = (props) => {
  const login = useLogin()
  const notify = useNotify()

  const loginWithWallet = async () => {
    let signedMessage
    const expiresAt = Date.now() + 1 * 60 * 1000
    const dataSignObject = { expiresAt, payload: loginMessage }
    const dataSign = JSON.stringify(dataSignObject)
    if (window.ethereum) {
      await connectMetamask(window.ethereum)
      signedMessage = await signMessage(window.ethereum, dataSign)
    } else {
      alert('Please install Metamask extension')
    }

    signedMessage.type = 'wallet'
    login(signedMessage).catch(() => notify('Invalid signature'))
  }

  const { children, backgroundImage, ...rest } = props
  const containerRef = useRef()
  let backgroundImageLoaded = false
  const checkAuth = useCheckAuth()
  const navigate = useNavigate()
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page
      })
  }, [checkAuth, navigate])

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image()
      img.onload = updateBackgroundImage
      img.src = backgroundImage
    }
  }

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage()
    }
  })

  return (
    <Root {...rest} ref={containerRef}>
      <Card className={LoginClasses.card}>
        <div className={LoginClasses.avatar}>
          <Avatar className={LoginClasses.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {isEmailLogin && children}
        {!isEmailLogin && (
          <>
            <Button variant="contained" color="primary" onClick={loginWithWallet} fullWidth>
              Login with wallet
            </Button>
            <div>{/*Reset password*/}</div>
          </>
        )}
      </Card>
    </Root>
  )
}

const PREFIX = 'RaLogin'
export const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

  [`& .${LoginClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
  },
  [`& .${LoginClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${LoginClasses.icon}`]: {
    backgroundColor: theme.palette.secondary[500],
  },
}))

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

Login.defaultProps = {
  children: <DefaultLoginForm />,
}
