import {
  List,
  Datagrid,
  ChipField,
  // ReferenceArrayField,
  // SingleFieldList,
  TextField,
  DeleteWithConfirmButton,
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
} from 'react-admin'

// <List filters={postFilters}>
// const postFilters = [
//     <TextInput source='q' label='Search' alwaysOn />,
//     <ReferenceInput source='userId' label='User' reference='users' />,
// ]

// <Edit title={<PostTitle />}>
// const PostTitle = () => {
//   const record = useRecordContext()
//   return <span>Post {record ? `'${record.title}'` : ''}</span>
// }

export const VocabularyList = () => (
  <List>
    <Datagrid>
      <TextField source="title" />
      <ChipField source="from" />
      <ChipField source="to" />
      <ChipField source="level" />
      <DeleteWithConfirmButton confirmContent="This action could not be reverted. Are you sure?" />
    </Datagrid>
  </List>
)

export const VocabularyCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" fullWidth/>
      <SelectInput source="from" choices={[
        { id: 'eng', name: 'English' },
        { id: 'rus', name: 'Russian' },
      ]} />
      <SelectInput source="to" choices={[
        { id: 'eng', name: 'English' },
        { id: 'rus', name: 'Russian' },
      ]} />
      <SelectInput source="level" choices={[
        { id: 'A1', name: 'A1' },
        { id: 'A2', name: 'A2' },
        { id: 'B1', name: 'B1' },
        { id: 'B2', name: 'B2' },
        { id: 'C1', name: 'C1' },
        { id: 'C2', name: 'C2' },
      ]} />
      <TextInput source="data" validate={[required()]} multiline fullWidth resettable/>
    </SimpleForm>
  </Create>
)
