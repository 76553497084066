import { Admin, Resource } from 'react-admin'
import { Dashboard } from './Dashboard'
import { Login } from './Login'

import { FilmCreateWithTMDB, FilmList, FilmEdit } from './resources/films'
import { ShowCreate, ShowList, ShowEdit } from './resources/shows'
import { VocabularyCreate, VocabularyList } from './resources/vocabularies'
import { UserList } from './resources/users'
import { UserProfileList } from './resources/userProfiles'

// https://mui.com/material-ui/material-icons/?theme=Outlined&query=report
import FilmIcon from '@mui/icons-material/Movie'
import ShowIcon from '@mui/icons-material/LiveTv'
import VocabularyIcon from '@mui/icons-material/School'
import UserIcon from '@mui/icons-material/Security'
import UserProfileIcon from '@mui/icons-material/Groups'

import { authProvider } from './authProvider'
import { dataProvider } from './dataProvider'

const App = () => (
  <Admin
    loginPage={Login}
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
  >
    <Resource
      name="films"
      list={FilmList}
      edit={FilmEdit}
      create={FilmCreateWithTMDB}
      recordRepresentation="title"
      icon={FilmIcon}
      options={{ label: 'Films' }}
    />
    <Resource
      name="shows"
      list={ShowList}
      edit={ShowEdit}
      create={ShowCreate}
      recordRepresentation="title"
      icon={ShowIcon}
      options={{ label: 'Shows' }}
    />
    <Resource
      name="vocabularies"
      list={VocabularyList}
      create={VocabularyCreate}
      recordRepresentation="from"
      icon={VocabularyIcon}
      options={{ label: 'Vocabularies' }}
    />
    <Resource
      name="userProfiles"
      list={UserProfileList}
      recordRepresentation="firstName"
      icon={UserProfileIcon}
      options={{ label: 'UserProfiles' }}
    />
    <Resource
      name="users"
      list={UserList}
      recordRepresentation="email"
      icon={UserIcon}
      options={{ label: 'Users' }}
    />
  </Admin>
)

export default App
