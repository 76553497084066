import {
  List,
  Datagrid,
  ChipField,
  NumberField,
  DateField,
  EmailField,
  // TextField,
  BooleanField,
} from 'react-admin'

// <List filters={postFilters}>
// const postFilters = [
//     <TextInput source='q' label='Search' alwaysOn />,
//     <ReferenceInput source='userId' label='User' reference='users' />,
// ]

// <Edit title={<PostTitle />}>
// const PostTitle = () => {
//   const record = useRecordContext()
//   return <span>Post {record ? `'${record.title}'` : ''}</span>
// }

export const UserList = () => (
  <List>
    <Datagrid>
      <ChipField source="role" />
      <NumberField source="acceptedTermsVersion" />
      <DateField source="lastAuthenticatedAt" />
      <EmailField source="email" />
      <BooleanField source="isEmailVerified" />
      <DateField source="verificationEmailResentAt" />
      <ChipField source="registeredWith" />
    </Datagrid>
  </List>
)
